import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {User, UserRoleEnum} from '../../models/user.model';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent implements OnInit {

  user: User;
  title: string;

  constructor(
      public dialogRef: MatDialogRef<AccountDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {user: User, title: string},
  ) {
    this.user = data.user;
    this.title = data.title;
  }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }
}
