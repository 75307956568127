<div class="row">
  <div class="col-1">
    <img style="width: 100%;" src="assets/logo.png" title="TotalEnergies" alt="TotalEnergies" class="logo" />
  </div>
  <div class="col-11">
    <header>
      <div class="d-flex align-items-center">
        <div class="app-name" (click)="goHome()">{{title}}</div>
        <ng-container *ngIf="userService.getUser() | async">
          <span class="pointer ml-auto d-flex align-items-center user" [matMenuTriggerFor]="account">
            <span class="username">{{userService.getUser().getValue().first_name}}
              {{userService.getUser().getValue().last_name}}</span>
            <mat-icon class="ml-3 user-icon" color="primary">account_circle</mat-icon>
          </span>
          <mat-menu class="mt-2" #account="matMenu" [overlapTrigger]="false">
            <button mat-menu-item routerLink="/auth/logout">{{ "menu_logout" | translate }}</button>
          </mat-menu>
          <span class="pointer d-flex align-items-center" (click)="downloadUserManual()">
            <mat-icon #tooltip="matTooltip" matTooltip="{{'app.download_user_manual' | translate}}" class="tooltip_icon ml-3 user-icon" color="primary">help
            </mat-icon>
          </span>
          <button mat-button [matMenuTriggerFor]="menu" class="lang-menu">
            {{translateService.currentLang | uppercase}}
            <mat-icon color="primary" class="arrow-icon">arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="updateLang('en')">En</button>
            <button mat-menu-item (click)="updateLang('fr')">Fr</button>
          </mat-menu>

        </ng-container>
      </div>
    </header>

    <mat-toolbar>

      <mat-toolbar-row *ngIf="userService.getUser() | async">
        <nav mat-tab-nav-bar style="width:100%;height: 100%">
          <ng-container *ngFor="let link of navLinks">
            <a *ngIf="displayNav(link) && (!link.children || link.children.length===0)" class="mat-tab-link" mat-tab-link [routerLink]="link.link" routerLinkActive #rla="routerLinkActive"
              [active]="rla.isActive" [class.active]="rla.isActive">
              {{ (link.label + '.title') | translate }}


            </a>

            <a *ngIf="displayNav(link) && link.children && link.children.length> 0" class="mat-tab-link" mat-tab-link routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
              [class.active]="rla.isActive" [matMenuTriggerFor]="menu">
              {{ (link.label + '.title') | translate }}

              <mat-menu #menu="matMenu" [overlapTrigger]="false" class="children">
                <ng-container *ngFor="let child of link.children">
                  <button mat-menu-item class="sub-menu-item" [routerLink]="link.link + '/' + child.link">
                    {{ (child.label + '.title') | translate }}
                  </button>
                </ng-container>
              </mat-menu>
            </a>
          </ng-container>

        </nav>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>