import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CRUDService {

  constructor(protected http: HttpClient) { }
  baseUrl = environment.api.host;
  modelName: string;

  protected getCount(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/' + this.modelName + '/count').pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  list(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/' + this.modelName).pipe(
      map(res => res.data),
      catchError(this.handleError)
    );
  }

  show(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/' + this.modelName + '/' + id).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  create(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/' + this.modelName, model).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  update(model: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/' + this.modelName + '/' + model.id, model).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  updateNameAndPosition(model: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/' + this.modelName + '/update-name/' + model.id, model).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  protected updateList(models: any[]): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/' + this.modelName, models).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/' + this.modelName + '/' + id).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.error.message || error.message || error);
  }
}
