import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@ff/user';
import { User } from '../../user/models/user.model';


@Injectable({
  providedIn: 'root'
})
export class IsNotAdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const authUser: User = new User(this.userService.getUser().getValue());
    if (authUser.isAdmin()) {
      this.router.navigate(['/']);
    }

    return true;
  }
}
