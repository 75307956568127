<div class="d-flex">
    <div class="dialog-title">{{ title | uppercase}}</div>
    <mat-icon class="ml-auto pointer" (click)="close()">clear</mat-icon>
</div>
<mat-dialog-content>
    <div class="username">{{user.first_name + ' ' + user.last_name + ' (' + user.igg + ')'}}</div>
    <div class="d-flex" style="margin-top: 30px">
        <div class="label">E-mail :</div>
        <a class="email" href="mailto:{{user.email}}">{{user.email}}</a>
    </div>
    <div class="d-flex">
        <div class="label">Profile :</div>
        <div class="info-data">{{user.role}}</div>
    </div>
</mat-dialog-content>
