import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaselineComponent} from './components/baseline/baseline.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [BaselineComponent],
  exports: [
    BaselineComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class BaselineModule {
}
