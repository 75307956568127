<div mat-dialog-content>
    <p>
        Welcome to the SLP Project Management website
        (hereinafter referred to as the “Website”).
        The access to the Site is reserved for TotalEnergies Marketing Services employees only.
    </p>

    <ol>
        <li>Cautionary Statements</li>
        <br />
        By accessing or browsing our Website:
        <ul>
            <li>
                you confirm that you have read, understood and agreed to these General Terms and Conditions of Use and Privacy Policy,
                which supplement other reference texts applicable across TotalEnergies Group, such as the Code of conduct, internal regulations (if relevant), etc.
            </li>
            <li>
                you undertake to refrain from any use that runs counter to regulations in force, public order
                and public morality. Data entered in the free comment fields must be adequate, relevant and not
                excessive. Consequently, comments must remain objective and not be offensive or violate the
                dignity of individuals. The Company formally declines any liability as to the content of private
                messages or free comments written by employees and addressed to other TotalEnergies Marketing Services
                employees.
            </li>
            <li>
                you acknowledge that this Website is reserved for professional use only.
            </li>
        </ul>
        <br />
        <li>Intellectual Property Rights</li>
        <br />
        <p>
            <span class="subtitle">a. Principle.</span>
            All the information or documents (texts, animated as well as static images, databases, sounds,
            photographs, expertise, products) contained on the Website, as well as its general structure and
            all the elements created for the Website, are either the property of TotalEnergies Group, or are subject
            to rights of use, reproduction and representation granted to the Group’s entities.<br />
            Such information, documents or elements are subject to copyright laws as soon as they are
            made available to the public on this Website. No license or right other than that of consulting
            the Website is granted to any person with respect to intellectual property rights. The reproduction
            of any element published on the Website is authorized for information purposes only and strictly for
            personal and private use. Any reproduction or use of a copy of the Website’s content is expressly prohibited
            and subject to the prior and express authorization of the Website Publisher. In any case, the authorized
            reproduction of the information contained in this Website must indicate the appropriate source and mention
            of property.
        </p>
        <p>
            <span class="subtitle">b. Distinctive signs.</span> Unless otherwise stated, the company names, logos,
            products and trademarks mentioned in this Website are the property of TotalEnergies Group, or are subject to
            rights of use, reproduction or representation granted to the latter. They may not be used without
            prior written consent from the Company.
        </p>
        <p>
            <span class="subtitle">c. Databases.</span> Any databases made available to you are the property
            of the Website Publisher, in its capacity as database producer. It is prohibited to extract or reuse
            a qualitatively or quantitatively substantial part of the databases, including for private purpose,
            in accordance with applicable regulations.
        </p>

        <li>Hypertext Links / Third-party Sites</li>
        <br />
        <p>
            You can insert hypertext links in your personalized messages. The Company formally declines any
            liability as to the content available on the sites to which said links connect.
        </p>

        <li>Personal Data</li>
        <br />
        <p>
            Total Marketing Services, as data controller, processes your personal data, transmitted via
            the AGIL application, to enable you to use the SLP Project Management website. Such information is necessary
            to enable authentication via “Digital Pass” and grant you access to the service; otherwise you
            will not be able to access the service. <br />
            Your personal data will not be further processed in a manner that is incompatible with the purposes
            described above. Your data will be kept for as long as you continue to be a SLP user.
        </p>
        <p>
            In accordance with the regulations in force, you have the right to request access to, as well as
            rectification and erasure of your personal data, and to object to its processing. You have the right
            to receive a copy of your personal data and to define instructions regarding the use of your personal
            data after your death. You also have the right to request data portability as well as a restriction
            of processing of your personal data.<br />
            You can exercise your rights and obtain information about how we process of your personal data
            via your personnel representatives. In addition, you have the right to lodge a complaint with the
            French data protection authority (CNIL).
        </p>
        <p>
            Appropriate measures are in place to ensure the security and confidentiality of your personal data
            and, in particular, to prevent this data from being distorted, damaged or accessed by unauthorized
            third parties.
        </p>

        <li>Updates to the Website Terms of Use and Applicable Law</li>
        <br />
        <p>
            The Company may update these General Terms and Conditions of Use and Privacy Policy at any
            time and we encourage you to periodically review the latest updates on this page. These General
            Terms and Conditions of Use and Privacy Policy are subject to French law and fall under the
            jurisdiction of the French courts.
        </p>

        <li>Legal Information</li>
        <br />
        <p>
            <b><i>Website Publisher</i></b><br />
            TotalEnergies Marketing Services<br />
            Public limited company under French law (Société anonyme), with share capital of €324,158,696<br />
            Registered Office located at 24 cours Michelet – Puteaux (92800) – France<br />
            Listed on the Nanterre Trade and Companies Register under number 542 034 921
        </p>
        <p>
            <b><i>Website Host:</i></b><br />
            TotalEnergies Marketing Services<br />
            Public limited company under French law (Société anonyme), with share capital of €324,158,696<br />
            Registered Office located at 24 cours Michelet – Puteaux (92800) – France<br />
            Listed on the Nanterre Trade and Companies Register under number 542 034 921
        </p>

        <li>Contact Information</li>
        <br />
        <p>
            If you have any questions concerning the General Terms and Conditions of Use and Privacy
            Policy of the Website, please contact us by email at TOCHANGE or by post
            at the following address:<br /><br />
            TotalEnergies Marketing Services, 24 cours Michelet - Puteaux (92800) – France
        </p>

        <li>Definitions of the terms</li>
        <br />
        <span class="subtitle">- TotalEnergies Group</span> : TotalEnergies S.A. or any other legal entity:
        <br /><br />
        <ul>
            <li>which directly or indirectly holds or would hold more than 50% of the share
                capital or voting rights at TotalEnergies S.A.'s ordinary meetings of shareholders;</li>
            Or,
            <li>of which more than 50% of the share capital or voting rights at ordinary meetings
                of shareholders are or would be held directly or indirectly by TotalEnergies S.A. or by an entity
                related to it as defined in indent 1/ above.</li>
        </ul>
        <br />
        <span class="subtitle">- The Company</span> : the publisher of this Website.
    </ol>
</div>