import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig, API_CONFIG } from '@ff/api';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsgService } from './msg.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _endpoint: string;
  private _headers: HttpHeaders;

  constructor(
    private _msgService: MsgService,
    private _http: HttpClient,
    @Inject(API_CONFIG) _apiConfig: ApiConfig
  ) {
    this._endpoint = _apiConfig.host;
    this._headers = new HttpHeaders({
      'Accept-Language' : 'fr, en',
    });
  }

  get<T>(resource: string, params: any = {}): Observable<any[] | T> {
    params.headers = new HttpHeaders({
      'Accept-Language' : 'fr, en',
    });

    return this._http.get<T>(this._endpoint + '/' + resource, params).pipe(
      catchError(this.handleError('get ' + resource, null))
    );
  }

  post<T>(resource: string, params?: T, options: any = {}): Observable<any[] | T> {
    if (!resource.indexOf('register')) {
      options.headers = new HttpHeaders({
        'Accept-Language': 'fr, en',
      });
    }
    return this._http.post<T>(this._endpoint + '/' + resource, params, options).pipe(
      catchError(this.handleError('post ' + resource, null))
    );
  }

  patch<T>(resource: string, params?: T, options: any = {}): Observable<any[] | T> {

    return this._http.patch<T>(this._endpoint + '/' + resource, params, options).pipe(
      catchError(this.handleError('patch ' + resource, null))
    );
  }

  delete<T>(resource: string, params?: object): Observable<any[] | T> {
    return this._http.delete<T>(this._endpoint + '/' + resource, params).pipe(
      catchError(this.handleError('delete ' + resource, null))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      if (response != null && response.error != null && response.error.message != null) {
        this._msgService.showSnackBar(response.error.message);
      }

      // return null as defined in crud method
      return of(result as T);
    };
  }

}
