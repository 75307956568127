import {Injectable, Optional, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConfig, API_CONFIG} from '@ff/api';
import {endpoints} from 'src/environments/endpoints';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  endpoints;

  constructor(
    private http: HttpClient,
    @Optional() @Inject(API_CONFIG) private apiConfig: ApiConfig,
  ) {
    this.endpoints = endpoints;
  }

  public updateUserLangPref(lang: string): Promise<any> {
    return this.http.post(this.apiConfig.host + this.endpoints.updateLang, {lang}).toPromise();
  }

  resetPasswordLink(form: FormGroup) {
    return this.http.post<{ data: any }>(this.apiConfig.host + this.endpoints.resetPasswordLink, form).toPromise();
  }
}
