import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AuthService } from '@ff/auth';
import { LoaderService } from '@ff/loader';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class RedirectWhenUnauthenticated implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private loader: LoaderService,
    private snackBar: MatSnackBar
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        err => {
          this.loader.hide();
          if (
            this.router.url !== '/' &&
            this.router.url !== '/auth/login' &&
            err instanceof HttpErrorResponse &&
            err.status === 401
          ) {
            this.auth.logout();
            this.router.navigate(['/auth/login']);
            this.snackBar.open(
              'Vous avez été déconnecté parce que votre session a expiré.',
              'Fermer',
              {
                duration: 3000
              }
            );
          }
          return throwError(event);
        }
      )
    );
  }
}
