<div class="container-fluid h-100 d-flex flex-column">
  <div class="row justify-content-center px-4">
    <form class="login-form d-flex align-items-center justify-content-center my-4" (ngSubmit)="login(loginForm)" [formGroup]="loginForm" novalidate>
      <mat-card class="col-12 col-md-8 col-lg-6 col-xl-5 p-5">
        <mat-card-content class="d-flex flex-column">
          <h2 class="mb-5">TotalEnergies | MultiProductStudio</h2>
          <mat-form-field class="mb-3">
            <input matInput type="email" formControlName="login" placeholder="Email" required />
            <mat-icon matSuffix>perm_identity</mat-icon>
          </mat-form-field>
          <mat-form-field class="mb-3">
            <input matInput type="password" formControlName="password" placeholder="Password" required />
            <mat-icon matSuffix>lock</mat-icon>
          </mat-form-field>
          <p class="forgotten-password align-self-end d-flex align-items-center pointer mb-4" (click)="showResetPasswordLinkDialog()">
            <mat-icon>arrow_right</mat-icon>
            Forgotten password?
          </p>
          <button mat-raised-button class="align-self-start px-3 py-1" color="primary" [disabled]="!loginForm.valid">
            <mat-icon>arrow_right</mat-icon>
            Connect
          </button>
          <ng-container *ngIf="(getOAuth2Providers() | async).length">
            <hr class="mb-2 mt-3" />
            <a *ngFor="let provider of getOAuth2Providers() | async" (click)="loginWithOAuth2(provider.url)" class="d-flex align-items-center justify-content-center ff-link">
              <mat-icon>arrow_right</mat-icon>
              Connect via {{provider.label}}
            </a>
          </ng-container>
          <p class="mt-5 text-center">
            <span class="cgu-link">
              <span>
                By continuing you agree to our
                <i class="pointer" (click)="showCGUDialog()"><u>Terms and Conditions and Privacy Policy</u></i>
              </span>
            </span>
          </p>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
