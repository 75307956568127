import {Directive, OnInit, ElementRef, OnDestroy, Renderer2, HostListener, Input} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {NgControl} from '@angular/forms';
import {FocusMonitor} from '@angular/cdk/a11y';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {isNumber} from 'lodash';

/**
 * Override in the DOM the value of the input to be displayed formatted according the en-US locale
 *   `,` as thousand separator
 *   `.` as decimal separator
 */
@Directive({
  selector: '[appFormatPercentageDisplay]',
  providers: [DecimalPipe]
})
export class FormatPercentageDisplayDirective implements OnInit, OnDestroy {

  get inputValue() {
    return this._control ? this._control.value : null;
  }

  constructor(
      private _elementRef: ElementRef,
      private _control: NgControl,
      private _renderer: Renderer2,
      private _decimalPipe: DecimalPipe,
      fm: FocusMonitor) {

    fm.monitor(_elementRef.nativeElement, true)
        .pipe(takeUntil(this.destroyed))
        .subscribe(origin => {
          const focused = !!origin;
          this.switchInputFormat(focused);
        });
  }

  destroyed = new Subject();
  /**
   * Radhouane
   * We are going to remove e and E that is considered number by type input number HTML
   */
  private specialKeys = [
    'e', 'E'
  ];

  ngOnInit() {
    this.switchInputFormat(false);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) === -1) {
      if (parseInt(this.inputValue + event.key, 10) > 100) {
        event.preventDefault();
      } else {
        return;
      }

    } else {
      event.preventDefault();
    }
  }

  switchInputFormat(focus: boolean) {
    const type = focus ? 'number' : 'text';
    const valueToSet = focus ? this.inputValue : this._decimalPipe.transform(this.inputValue, '1.0-2');
    this._renderer.setAttribute(this._elementRef.nativeElement, 'type', type);
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', valueToSet);
  }

  ngOnDestroy() {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
