import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../../shared/services/user.service';
import { LoaderService } from '@ff/loader';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../../../../environments/environment';

declare const grecaptcha: any;

@Component({
  selector: 'app-auth-reset-password-link',
  templateUrl: './resetPasswordLink.component.html',
  styleUrls: ['./resetPasswordLink.component.scss']
})
export class ResetPasswordLinkComponent implements OnInit {

  @Input() token: string;
  public reCaptchaKey = environment.reCaptchaKey;

  private _loading: boolean;
  public form: FormGroup;
  public resetPasswordLinkSuccessMessage: string;
  public resetPasswordLinkErrorMessage: any;
  public resetPasswordLinkForm: FormGroup;

  public error: any;

  constructor(
    fb: FormBuilder,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ResetPasswordLinkComponent>,
    private _loaderService: LoaderService
  ) {
    this.error = {};

    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      token: ['']
    });

  }

  public ngOnInit() {
    this.addScript();
  }

  addScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=` + this.reCaptchaKey;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  getToken() {
    return grecaptcha.execute(environment.reCaptchaKey, { action: 'submit' }).then(function (token) {
      return token;
    });
  }

  public submit() {
    this.getToken().then(
      (token) => {
        this.form.patchValue({ token });
        if (this.form.valid && !this._loaderService.isVisible()) {

          this._loaderService.show();

          this._userService.resetPasswordLink(this.form.getRawValue()).then(
            (response: any) => {
              this.form.reset();
              this._loaderService.hide();
              this._snackBar.open(response.message, 'Close', {
                duration: 5000
              });
              this._dialogRef.close();
            }
          ).catch(
            (response) => {
              this._loaderService.hide();
              const message = response.error.errors ? response.error.errors.password[0] : response.error.message;
              this._snackBar.open(message, 'Close', {
                duration: 5000
              });
            }
          );
        }
      }
    );
  }
}
