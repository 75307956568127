import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareBaselineService {

  private sharedBaseline = new BehaviorSubject([]);
  currentBaseline = this.sharedBaseline.asObservable();

  constructor() { }

  setBaseline(baseline: any[]) {
    this.sharedBaseline.next(baseline);
  }
}
