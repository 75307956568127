import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  _elements: any[];

  @Input() title: string;
  @Input() get elements(): any[] {
    return this._elements;
  };

  @Input() hasSearchBar = true;

  set elements(value: any[]) {
    this._elements = value;
  }

  @Output() elementsChange = new EventEmitter<any[]>();
  @Input() placeholder: string;

  allElements;

  constructor() { }

  ngOnInit(): void {
    this.allElements = this.elements;
  }

  applyFilter(filterValue: string) {
    const filteredElts = this.allElements;
    this.elementsChange.emit(
      filteredElts.filter(
          elt => JSON.stringify(elt).toLowerCase().includes(filterValue.trim().toLowerCase())
      )
    );
  }

}
