import { BrowserModule } from '@angular/platform-browser';
import { Inject, NgModule, Optional, SkipSelf } from '@angular/core';


import { ThemeComponent } from '@ff/theme';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { USER_CONFIG } from '@ff/user';
import { AUTH_CONFIG } from '@ff/auth';
import { API_CONFIG } from '@ff/api';
import { OAUTH2_CONFIG } from '@ff/oauth2';
import { routes } from './core.routes';
import { AuthModule } from '@ff/auth';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { ResetPasswordLinkComponent } from '../auth/components/reset-password-link/resetPasswordLink.component';
import { createTranslateLoader, SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RedirectWhenUnauthenticated } from './interceptors/redirect-when-unauthenticated.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { BaselineModule } from '../baseline/baseline.module';
import { ATInternetModule, ATInternetService, AT_INTERNET_CONFIG } from '@ff/at-internet';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppAuthModule,
    UserModule,
    SharedModule,
    MatMenuModule,
    BaselineModule,
    ATInternetModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      }
    }),
  ],

  providers: [
    {
      provide: AT_INTERNET_CONFIG,
      useValue: {
        site: environment.xitiSiteId,
        autosend: true,
        secure: true,
        collectDomain: 'logc409.xiti.com',
        collectDomainSSL: 'logs1409.xiti.com',
      }
    },
    {
      provide: API_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: AUTH_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: USER_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: OAUTH2_CONFIG,
      useValue: {
        host: environment.api.host,
        endpoints: {
          bearer: '/oauth2/token',
          providers: '/oauth2/providers'
        }
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectWhenUnauthenticated,
      multi: true
    },
    ResetPasswordLinkComponent,
  ],
  bootstrap: [AppComponent]
})
export class CoreModule {
  public constructor(
    @Optional() @SkipSelf() parent: CoreModule,
    // tslint:disable-next-line:variable-name
    @Inject(ATInternetService) _aTInternetService: ATInternetService) {
    if (parent) {
      throw new Error('CoreModule should not be exported then imported by another module!');
    }

  }
}
