import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routes } from './auth.routes';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordComponent } from './components/reset-password/resetPassword.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/resetPasswordLink.component';
import { OAuth2Module } from '@ff/oauth2';
import { CguComponent } from './components/cgu/cgu.component';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ResetPasswordLinkComponent,
    CguComponent
  ],
  providers: [
  ],
  imports: [
    OAuth2Module,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    CommonModule,
    SharedModule
  ],
})
export class AuthModule {

  public constructor() {

  }

}
