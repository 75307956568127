import {Routes} from '@angular/router';
import {LoggedInGuard} from '@ff/core';
import {AccountDialogComponent} from './components/account-dialog/account-dialog.component';

export const routes: Routes = [
  {
    path: 'user',
    children: [
      {path: 'my-account', component: AccountDialogComponent, canActivate: [LoggedInGuard]}
    ]
  }
];
