<div class="title-searchbar">
    <div class="primary-title">
        {{title}}
    </div>

    <div class="search-container" *ngIf="hasSearchBar">
        <div class="searchBar">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{placeholder}}">
            <mat-icon matSuffix>search</mat-icon>
        </div>
    </div>
</div>
