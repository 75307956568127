import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './user.routes';
import { AccountDialogComponent } from './components/account-dialog/account-dialog.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AccountDialogComponent
  ],
  providers: [
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class UserModule {

  public constructor() {

  }

}
