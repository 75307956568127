import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { UserService as SharedUserService } from 'src/app/modules/shared/services/user.service';
import { User } from 'src/app/modules/user/models/user.model';
import { environment } from 'src/environments/environment';
const ENV = environment;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() public nav: MatSidenav;
    loggedIn = false;
    displayMenu = true;
    currentLang: string;
    currentUser: User;
    navLinks: any[];
    activeLinkIndex = -1;
    blCount: any[];
    title = 'NP4U';

    constructor(
        public userService: UserService,
        public authService: AuthService,
        public translateService: TranslateService,
        public sharedUserService: SharedUserService,
        private titleService: Title,
        private router: Router,
    ) {
        this.navLinks = [
            {
                label: 'np_distribution',
                link: './np-distribution',
                index: 0,
                readerUser: true,
                operatorUser: true,
                onlyAdmin: false
            },
            {
                label: 'dashboard',
                link: './dashboard',
                index: 1,
                readerUser: true,
                operatorUser: true,
                onlyAdmin: false
            },
            {
                label: 'administration.program_administration',
                link: 'administration/program-administration',
                index: 0,
                readerUser: true,
                operatorUser: true,
                onlyAdmin: true
            },
            {
                label: 'administration.user_management',
                link: 'administration/user-management',
                index: 1,
                readerUser: true,
                operatorUser: true,
                onlyAdmin: true
            }
        ];
    }

    ngOnInit() {
        if (!ENV.production) {
            this.title = this.title + ' - ' + ENV.name;
            this.titleService.setTitle(this.title);
        }


        this.loggedIn = this.authService.isLoggedIn().getValue();
        if (this.loggedIn) {
            this.currentUser = new User(this.userService.getUser().getValue());
        }
        this.translateService.getDefaultLang();
    }

    updateLang(lang: string = 'en') {
        this.translateService.use(lang);
        this.sharedUserService.updateUserLangPref(lang);
    }

    logout() {

    }


    displayNav(link) {
        if (this.loggedIn) {
            if (link.onlyAdmin === true && !this.currentUser.isAdmin()) {
                return false;
            }
            if (link.onlyAdmin === false && this.currentUser.isAdmin()) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }

    downloadUserManual() {
        window.open(ENV.api.host + '/storage/UserManualNP4U.' + (this.translateService.currentLang || this.translateService.getDefaultLang()) + '.pdf', "_blank");
    }

    goHome() {
        this.router.navigate(['/']);
    }
}
