import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe, DecimalPipe, UpperCasePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';

import { FormsModule } from '@angular/forms';
import { LoaderModule, LoaderService } from '@ff/loader';
import { CoreModule } from '@ff/core';
import { ThemeModule } from '@ff/theme';
import { NavigationModule } from '@ff/navigation';
import { DialogComponent } from './components/dialog/dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { FormatNumberDisplayDirective } from './directives/format-number-display.directive';

import { FormatPercentageDisplayDirective } from './directives/format-percentage-display.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GuestGuard } from './guards/guest.guard';
import { MyMatPaginatorIntl } from './helpers/my-mat-paginator-intl';
import { ChartsModule } from 'ng2-charts';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DialogComponent,
    FormatNumberDisplayDirective,
    FormatPercentageDisplayDirective,
    RecaptchaDirective,
    SearchbarComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl },
    DatePipe,
    UpperCasePipe,
    DecimalPipe,
    GuestGuard
  ],
  imports: [

    // Angular

    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // FF Packages
    LoaderModule,
    CoreModule,
    NavigationModule,
    ThemeModule,

    // Others

    // Angular Material
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatMomentDateModule,
    MomentDateModule,
    MatExpansionModule,
    MatListModule,
    MatAutocompleteModule,

    TranslateModule.forChild(),
    MatExpansionModule,

    // Charts
    ChartsModule
  ],
  exports: [

    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // FF Packages
    LoaderModule,
    CoreModule,
    ThemeModule,
    NavigationModule,

    // Angular Material
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatMomentDateModule,
    MomentDateModule,
    MatExpansionModule,
    MatListModule,
    MatAutocompleteModule,

    // Component

    FormatNumberDisplayDirective,
    FormatPercentageDisplayDirective,
    RecaptchaDirective,
    SearchbarComponent,
    TranslateModule,

    // Charts
    ChartsModule
  ]
})
export class SharedModule {

  public constructor() {

  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loaderShow(loaderService: LoaderService) {
  if (!loaderService.isVisible()) {
    loaderService.show();
  }
}

export function loaderHide(loaderService: LoaderService) {
  if (loaderService.isVisible()) {
    loaderService.hide();
  }
}

export function isFileTypeAllowed(file, result, allowedTypes = []) {
  if (file) {
    return new Promise(
      (resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e: any) => {
          const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
          let header = '';
          for (const byte of arr) {
            header += byte.toString(16);
          }
          result = allowedTypes.includes(header);
          resolve(result);
        };
        fileReader.readAsArrayBuffer(file);
      }
    );
  }
}
