import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
    title: string;
    text: string;
    value: string;
    type: string;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    text_yes: string;
    text_no: string;
    button_save: string;
    button_cancel: string;
    text_submit: string;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.text_yes = this.translateService.instant('general_labels.yes');
        this.text_no = this.translateService.instant('general_labels.no');
        this.text_submit = this.translateService.instant('general_labels.validate');
        this.button_save = 'Next';
        this.button_cancel = this.translateService.instant('general_labels.cancel');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onClick(): void {
        this.dialogRef.close(this.data.value);
    }
}
