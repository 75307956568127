import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
//import { NgxCookieBannerComponent } from 'ngx-cookie-banner';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cookie', { static: true })
  //banner: NgxCookieBannerComponent;

  year = moment().format('YYYY');

  private cookieSub: Subscription;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //this.cookieSub = this.banner.isSeen.subscribe();
  }

  ngOnDestroy() {
    this.cookieSub.unsubscribe();
  }

}
