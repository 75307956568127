import { Organisation } from "src/app/models/Organisation";

export class User {
  email: string;
  // tslint:disable-next-line:variable-name
  first_name: string;
  id: number;
  // tslint:disable-next-line:variable-name
  last_name: string;

  igg: string;
  role: string;

  roles: any[];
  organisation: Organisation;

  constructor(userValue?) {
    this.email = userValue?.email;
    this.first_name = userValue?.first_name;
    this.id = userValue?.id;
    this.last_name = userValue?.last_name;
    this.igg = userValue?.igg;
    this.role = userValue?.role;
    this.roles = userValue?.roles;
    this.organisation = userValue?.organisation;
  }

  isAdmin(): boolean {
    return this.role === 'Admin';
  }


  isRH(): boolean {
    return this.role === 'RH';
  }

  isBranch(): boolean {
    return this.role === 'Branch';
  }

  isCDEP(): boolean {
    return this.role === 'CDEP';
  }

  isAffiliate(): boolean {
    return this.role === 'Affiliate';
  }

  isMN1(): boolean {
    return this.role === 'Manager N-1';
  }

  isMN2(): boolean {
    return this.role === 'Manager N-2';
  }

  isRhAtBranchLevel() {
    return this.isRH() && !this.organisation.direction;
  }

  isRhAtCDEPLevel() {
    return this.isRH() && this.organisation.direction && !this.organisation.division && !this.organisation.affiliate;
  }

  isRhAtAffiliateLevel() {
    return this.isRH() && this.organisation.affiliate && !this.organisation.division;
  }

  isRhAtMN1Level() {
    return this.isRH() && this.organisation.division && !this.organisation.department;
  }

  isRhAtMN2Level() {
    return this.isRH() && this.organisation.department;
  }

  hasBranchLevel() {
    return ['RH', 'Branch'].indexOf(this.role) > -1;
  }

  hasDirectionLevel() {
    return ['RH', 'Branch', 'CDEP'].indexOf(this.role) > -1;
  }

  hasAffiliateLevel() {
    return ['RH', 'Branch', 'CDEP', 'Affiliate'].indexOf(this.role) > -1;
  }

  hasDivisionLevel() {
    return ['RH', 'Branch', 'CDEP', 'Affiliate', 'Manager N-1'].indexOf(this.role) > -1;
  }

  hasDepartmentLevel() {
    return ['RH', 'Branch', 'CDEP', 'Affiliate', 'Manager N-1', 'Manager N-2'].indexOf(this.role) > -1;
  }

  isNpManipulator() {
    return ['Branch', 'CDEP', 'Affiliate', 'Manager N-1', 'Manager N-2'].indexOf(this.role) > -1;
  }



}

export enum UserRoleEnum {
  Admin = 'Admin',
  ManagerN1 = 'Manager N-1',
  ManagerN2 = 'Manager N-2',
  CDEP = 'CDEP',
  RH = 'RH',
}

