import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from '@ff/core';
import { IsAdminGuard } from '../shared/guards/is-admin.guard';
import { IsNotAdminGuard } from '../shared/guards/is-not-admin';
import { RedirectGuard } from '../shared/guards/redirect-guard';


export const routes: Routes = [
  {
    path: 'np-distribution',
    loadChildren: () => import('../np-distribution/np-distribution.module').then((m) => m.NpDistributionModule),
    canActivate: [LoggedInGuard, IsNotAdminGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoggedInGuard, IsNotAdminGuard],
  },
  {
    path: 'administration',
    loadChildren: () => import('../administration/administration.module').then((m) => m.AdministrationModule),
    canActivate: [LoggedInGuard, IsAdminGuard],
  },
  { path: '**', children: [], pathMatch: 'full', canActivate: [RedirectGuard] },
  { path: '', children: [], pathMatch: 'full', canActivate: [RedirectGuard] }
];

