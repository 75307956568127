import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from '../modules/shared/services/api.service';
import { EntitiesService } from './entities.service';

const httpOptionsUpload = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends EntitiesService {

  private formData: FormData = new FormData();

  constructor(protected http: HttpClient,
    private _apiService: ApiService,) {
    super(http);
  }

  baseUrl = environment.api.host;

  getOrganisationBaseline(organisationId): Observable<any> {
    return this.http.get(this.baseUrl + '/organisations/baseline' + (organisationId ? ('/' + organisationId) : ''), httpOptionsUpload).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  upload(file): Observable<any> {
    this.formData = new FormData();
    this.formData.append('file', file);
    return this.http.post(this.baseUrl + '/organisation/upload', this.formData, httpOptionsUpload).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  search(arg): Observable<any> {
    return this._apiService.get(`organisation/search?arg=${arg}`).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    if (error.status === 422) {
      return Promise.reject(error.error);
    }
    return Promise.reject(error.error.message || error.message || error);
  }

}
