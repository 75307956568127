import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyMatPaginatorIntl extends MatPaginatorIntl {

  private rangeLabelIntl: string;

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this.getTranslations();
    this.translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {

    this.translateService.get([
      'PAGINATOR.ITEMS_PER_PAGE',
      'PAGINATOR.NEXT_PAGE',
      'PAGINATOR.PREVIOUS_PAGE',
      'PAGINATOR.RANGE'
    ]).subscribe(translation => {
      this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
      this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
      this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
      this.rangeLabelIntl = translation['PAGINATOR.RANGE'];
      this.changes.next();
    });

  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };

}