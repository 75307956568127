import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {EntitiesService} from './entities.service';

const httpOptionsUpload = {
  headers: new HttpHeaders({})
};

@Injectable({
  providedIn: 'root'
})
export class BaselineService extends EntitiesService {

  private formData: FormData = new FormData();

  constructor(protected http: HttpClient) {
    super(http);
  }

  baseUrl = environment.api.host;

  getBaselines(): Observable<any> {
    return this.http.get(this.baseUrl + '/baselines', httpOptionsUpload).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  getFilteredBaselines(filter) {
    return this.http.post(this.baseUrl + '/baselines-list', filter, httpOptionsUpload).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  upload(file): Observable<any> {
    this.formData = new FormData();
    this.formData.append('file', file);
    return this.http.post(this.baseUrl + '/baselines/upload', this.formData, httpOptionsUpload).pipe(
      map(res => res),
      catchError(this.handleError)
    );
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    if (error.status === 422) {
      return Promise.reject(error.error);
    }
    return Promise.reject(error.error.message || error.message || error);
  }

}
