import {
    Directive,
    Input,
    ElementRef,
    OnInit,
    NgZone,
    Injector,
    AfterViewInit,
    forwardRef
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR, AbstractControl} from '@angular/forms';

export interface ReCaptchaConfig {
    theme?: 'dark';
    type?: 'audio' | 'image';
    size?: 'compact' | 'normal';
    tabIndex?: number;
}

@Directive({
    selector: '[appNbRecaptcha]',
    exportAs: 'nbRecaptcha',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecaptchaDirective),
            multi: true
        }
    ]
})
export class RecaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {
    @Input() config: ReCaptchaConfig = {};

    private control: AbstractControl;

    @Input() key: string;
    @Input() lang: string;

    private onChange: (value: string) => void;
    private onTouched: (value: string) => void;

    private widgetId: number;

    constructor(private element: ElementRef, private ngZone: NgZone, private injector: Injector) {}

    ngOnInit() {
        this.registerReCaptchaCallback();
        this.addScript();
    }

    ngAfterViewInit() {
        this.control = this.injector.get(NgControl).control;
    }

    registerReCaptchaCallback() {
        window.reCaptchaLoad = () => {
            const config = {
                ...this.config,
                sitekey: this.key,
                callback: this.onSuccess.bind(this),
                'expired-callback': this.onExpired.bind(this)
            };
            this.widgetId = this.render(this.element.nativeElement, config);
        };
    }

    private render(element: HTMLElement, config): number {
        return grecaptcha.render(element, config);
    }

    addScript() {
        const script = document.createElement('script');
        const lang = this.lang ? '&hl=' + this.lang : '';
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    onSuccess(token: string) {
        this.ngZone.run(() => {
            this.control.setValue(token);
            this.registerOnChange(token);
            this.registerOnTouched(token);
        });
    }

    onExpired() {
        this.ngZone.run(() => {
            this.control.setValue(null);
            this.registerOnChange(null);
            this.registerOnTouched(null);
        });
    }

    // Value Accessor part

    writeValue(obj: any): void {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}

declare const grecaptcha: any;

declare global {
    interface Window {
        grecaptcha: any;
        reCaptchaLoad: () => void;
    }
}
