import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@ff/user';
import { LoaderService } from '@ff/loader';
import { equals, password } from '@ff/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-auth-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form: FormGroup;
  public resetPasswordErrorMessage: any;
  public resetPasswordSuccessMessage: any;

  public error: any;

  constructor(
    fb: FormBuilder,
    private _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ResetPasswordComponent>,
    private _loaderService: LoaderService
  ) {

    if (!data.token) {
      throw new Error('ResetPasswordComponent: token not provided!');
    }

    this.error = {};

    this.form = fb.group({
      email: ['', [Validators.email, Validators.required]],
      token: [data.token, Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), password()]],
      password_confirmation: ['', [Validators.required, equals('password')]]
    });
  }

  public ngOnInit() {
  }

  public submit() {

    if (this.form.valid && !this._loaderService.isVisible()) {

      this._loaderService.show();

      this._userService.resetPassword(this.form).then(
        (response) => {
          this.form.reset();
          this._loaderService.hide();
          this._snackBar.open(response.message, 'Close', {
            duration: 5000
          });
          this._dialogRef.close();
        }
      ).catch(
        (response) => {
          this._loaderService.hide();
          const message = response.error.errors ? response.error.errors.password[0] : response.error.message;
          this._snackBar.open(message, 'Close', {
            duration: 5000
          });
        }
      );
    }
  }

}
