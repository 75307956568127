import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaselineService } from '../../../../services/baseline.service';
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { loaderHide } from '../../../shared/shared.module';
import { forkJoin, Subject } from 'rxjs';
import { User } from '../../../user/models/user.model';
import { OrganisationService } from '../../../../services/organisation.service';
import { DecimalPipe } from '@angular/common';
import { ShareBaselineService } from '../../../../services/share-baseline.service';
import { UserService } from '@ff/user';

@Component({
  selector: 'app-baseline',
  templateUrl: './baseline.component.html',
  styleUrls: ['./baseline.component.scss']
})
export class BaselineComponent implements OnInit, OnDestroy {
  @Input() isDashboard = false;
  ngDestroy$ = new Subject();
  data: any = [];
  displayedColumns: string[] = ['name', 'np10', 'np11', 'np12', 'np13', 'np14', 'np15', 'np16', 'np17', 'np18'];
  dataSource: MatTableDataSource<any>;
  baselinesData: [];
  propositionsData: [];
  gapRow: any;

  baselineNotFound = false;
  constructor(private baselineService: BaselineService,
    private organisationService: OrganisationService,
    private _decimalPipe: DecimalPipe,
    private sharedBaselineService: ShareBaselineService,
    public userService: UserService) {
  }

  ngOnInit(): void {
    this.getBaseline();
  }

  public getBaseline() {
    this.baselineNotFound = false;
    this.sharedBaselineService.currentBaseline.subscribe(baselines => {
      this.data = [];
      if (baselines !== [] && baselines.length) {
        baselines.forEach(baseline => {
          if (baseline.name === 'baseline') {
            this.baselinesData = baseline;
          }
          if (baseline.name === 'propositions') {
            this.propositionsData = baseline;
          }
        });
        if (this.propositionsData !== [] && this.baselinesData !== []) {
          this.gapRow = [];
          this.gapRow.name = 'gap';
          for (const [key, value] of Object.entries(this.baselinesData)) {
            if (this.propositionsData[key]) {
              if (typeof value === 'number') {
                this.gapRow[key] = this.propositionsData[key] - value;
              }
            }
          }
        }

        this.data.push(this.propositionsData);
        this.data.push(this.baselinesData);
        this.data.push(this.gapRow);

        this.dataSource = new MatTableDataSource<any>(this.data);
      } else {
        this.baselineNotFound = true;
      }
    });
  }

  getBaselineValue(percentage) {
    if (!percentage) {
      return '0';
    } else {
      return this._decimalPipe.transform(percentage, '1.2-2');
    }
  }

  gapColor(row, key) {
    if (this.isBaselineRow(row)) {
      return 'baseline-row';
    } else {
      return this.getPropositionCellClass(row, key);
    }
  }

  private getPropositionCellClass(row, key) {
    if (this.propositionsData[key]) {
      const diff = parseFloat(this.baselinesData[key]) - parseFloat(this.propositionsData[key]);

      if (diff >= 0) {
        return 'green-gap';
      } else if (diff < -1) {
        return 'red-gap';
      } else {
        return 'yellow-gap';
      }
    } else {
      return 'green-gap';
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  isBaselineRow(row) {
    return row.name === 'baseline';
  }

}
