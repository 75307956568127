<div class="baseline-container" [ngClass]="{'blue-border' : !isDashboard}" *ngIf="baselinesData">
  <mat-accordion displayMode="flat">
    <mat-expansion-panel class="mat-elevation-z0" [hideToggle]="isDashboard" [expanded]="isDashboard" [disabled]="isDashboard">
      <mat-expansion-panel-header collapsedHeight="45px" expandedHeight="45px">
        <mat-panel-title>
          {{'baseline.title' | translate | uppercase}} | {{userService._user.getValue().organisation.name | uppercase}}
        </mat-panel-title>
      </mat-expansion-panel-header>


      <div [ngClass]="{'blue-border' : isDashboard}">

        <table mat-table [dataSource]="dataSource" class="baseline-table">
          <!-- Number Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="left_content">NP</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'baseline-row': isBaselineRow(row) }" class="table-first-col">{{'baseline.' + row.name | translate | uppercase}}</td>
          </ng-container>

          <!-- Title Column -->
          <ng-container matColumnDef="np10">
            <th mat-header-cell *matHeaderCellDef class="left_content">10</th>
            <td mat-cell *matCellDef="let row" [ngClass]="gapColor(row, 'NP10')">
              {{getBaselineValue(row.NP10)}}%
            </td>
          </ng-container>

          <!-- State Column -->
          <ng-container matColumnDef="np11">
            <th mat-header-cell *matHeaderCellDef class="left_content">11</th>
            <td mat-cell *matCellDef="let row" [ngClass]="gapColor(row, 'NP11')">
              {{getBaselineValue(row.NP11)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np12">
            <th mat-header-cell *matHeaderCellDef class="left_content">12</th>
            <td mat-cell *matCellDef="let row" [ngClass]="gapColor(row, 'NP12')">
              {{getBaselineValue(row.NP12)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np13">
            <th mat-header-cell *matHeaderCellDef class="left_content">13</th>
            <td mat-cell *matCellDef="let row" [ngClass]="gapColor(row, 'NP13')">
              {{getBaselineValue(row.NP13)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np14">
            <th mat-header-cell *matHeaderCellDef class="left_content">14</th>
            <td mat-cell *matCellDef="let row" [ngClass]="gapColor(row, 'NP14')">
              {{getBaselineValue(row.NP14)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np15">
            <th mat-header-cell class="grey-background" *matHeaderCellDef class="left_content">15</th>
            <td mat-cell *matCellDef="let row" class="grey-background">
              {{getBaselineValue(row.NP15)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np16">
            <th mat-header-cell class="grey-background" *matHeaderCellDef class="left_content">16</th>
            <td mat-cell *matCellDef="let row" class="grey-background">
              {{getBaselineValue(row.NP16)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np17">
            <th mat-header-cell class="grey-background" *matHeaderCellDef class="left_content">17</th>
            <td mat-cell *matCellDef="let row" class="grey-background">
              {{getBaselineValue(row.NP17)}}%
            </td>
          </ng-container>

          <ng-container matColumnDef="np18">
            <th mat-header-cell class="grey-background" *matHeaderCellDef class="left_content">18</th>
            <td mat-cell *matCellDef="let row" class="grey-background">
              {{getBaselineValue(row.NP18)}}%
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>


    </mat-expansion-panel>
  </mat-accordion>
</div>