import { Component } from '@angular/core';
import { ATInternetService } from '@ff/at-internet';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'NP4U';

  constructor(
    private translateService: TranslateService,
    public userService: UserService,
    public authService: AuthService,
    public atInternet: ATInternetService,
  ) {
    translateService.setDefaultLang('en');
    if (this.userService && this.userService._user && this.userService._user.getValue()) {
      const user = this.userService._user.getValue();
      if (user.lang) {
        translateService.use(user.lang);
      } else {
        translateService.use('en');
      }
    }
  }



}
