import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CRUDService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService extends CRUDService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  count<T>(modelName: string): Observable<T> {
    this.modelName = modelName;
    return this.getCount();
  }

  get<T>(modelName: string, id: number): Observable<T> {
    this.modelName = modelName;
    return this.show(id);
  }

  getAll<T>(modelName: string): Observable<any> {
    this.modelName = modelName;
    return this.list();
  }

  post<T>(modelName: string, model: any): Observable<any> {
    this.modelName = modelName;
    return this.create(model);
  }

  put<T>(modelName: string, model: any): Observable<T> {
    this.modelName = modelName;
    return this.update(model);
  }

  partialUpdate<T>(modelName: string, model: any): Observable<T> {
    this.modelName = modelName;
    return this.updateNameAndPosition(model);
  }

  massUpdate<T>(modelName: string, models: any[]): Observable<T[]> {
    this.modelName = modelName;
    return this.updateList(models);
  }

  destroy<T>(modelName: string, id: number): Observable<T> {
    this.modelName = modelName;
    return this.delete(id);
  }
}
